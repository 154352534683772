import { Row } from '@/components/Row';
import { useNavbarItems, useScreenSize } from '@/hooks';
import { theme } from '@/theme';
import { SessionI } from '@/types/cyclone/requests';
import { faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import React, { FunctionComponent, MutableRefObject } from 'react';

type DropdownMenuProps = {
  session: SessionI | null;
  showDropdownMenu: boolean;
  closeDropdownMenu: () => void;
  toggleDropdown: () => void;
  isWhiteNavbar: boolean;
  handleItemClick: (callback: () => void, onClick?: () => void) => void;
  profileRef: MutableRefObject<HTMLDivElement | null>;
  showMobile?: boolean;
};

export const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({
  session,
  showDropdownMenu,
  closeDropdownMenu,
  toggleDropdown,
  isWhiteNavbar,
  handleItemClick,
  profileRef,
  showMobile = false
}) => {
  const { dropdownItems: items } = useNavbarItems();
  const { isMobile } = useScreenSize();

  return (
    <div ref={profileRef} className={classNames('relative')}>
      <Row align="center" className="cursor-pointer" onClick={toggleDropdown}>
        {session?.avatar_url ? (
          <Image
            alt="avatar"
            src={`${session?.avatar_url}_100.webp`}
            width={24}
            height={24}
            className="rounded-full"
          />
        ) : (
          <FontAwesomeIcon
            icon={faUser}
            width="24px"
            height="24px"
            color={!isWhiteNavbar ? theme.colors.white : theme.colors.grey[800]}
          />
        )}
        <>
          <span
            className={classNames('ml-2 mr-2', {
              'text-white': !isWhiteNavbar,
              'text-[#626262]': isWhiteNavbar
            })}
          >
            {session?.first_name || ''}
          </span>
          <FontAwesomeIcon
            icon={faChevronDown}
            width="12px"
            height="12px"
            color={!isWhiteNavbar ? theme.colors.white : theme.colors.grey[800]}
          />
        </>
      </Row>
      <div
        className={classNames(
          'absolute top-10 right-0 rounded w-max p-2 border border-[868686] bg-white transition-opacity duration-300 ease-in-out',
          { 'opacity-0 pointer-events-none': !showDropdownMenu },
          { 'opacity-100 pointer-events-auto': showDropdownMenu }
        )}
      >
        {items
          .filter(({ show }) => show !== false)
          .map((item) => {
            const { label, onClick, icon } = item;
            return (
              <Row align="center" className="cursor-pointer py-2 px-1" key={label} onClick={onClick}>
                {icon && (
                  <FontAwesomeIcon icon={icon} color="#868686" width="22px" height="22px" fixedWidth />
                )}
                <span className="ml-2 text-[#868686]">{label}</span>
              </Row>
            );
          })}
      </div>
    </div>
  );
};
